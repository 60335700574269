export function convertDateFormat(dateStr: string, format: string = 'yyyy-mm-dd'): string {
    const date = new Date(dateStr);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return format.replace('yyyy', year.toString()).replace('mm', month).replace('dd', day);
} 

export function getHoursAndMinutes(dateStr: string): string {
    const date = new Date(dateStr);
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
}

// Converts a UTC date string to a local date in 'yyyy-mm-dd' format
export function convertUTCToDate(utcDateStr: string, format: string = 'yyyy-mm-dd'): string {
    const date = new Date(utcDateStr);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return format.replace('yyyy', year.toString()).replace('mm', month).replace('dd', day);
}

// Converts a UTC date string to a readable local date and time format (e.g., "Jan 1, 2023, 10:30 AM")
export function readableUTCDate(utcDateStr: string): string {
    const date = new Date(utcDateStr);
    return date.toLocaleString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
        hour12: true
    });
}

// Calculates the difference in days between two date strings
export function daysBetweenDates(dateStr1: string, dateStr2: string): number {
    const date1 = new Date(dateStr1);
    const date2 = new Date(dateStr2);
    const timeDifference = Math.abs(date2.getTime() - date1.getTime());
    return Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
}

// Checks if a date string represents a past date
export function isPastDate(dateStr: string): boolean {
    const today = new Date();
    const date = new Date(dateStr);
    return date < today;
}

// Formats a date string as 'Month Day, Year' (e.g., "January 1, 2023")
export function longDateFormat(dateStr: string): string {
    const date = new Date(dateStr);
    return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    });
}

// Formats a date string to ISO 8601 format (e.g., "2023-01-01T10:30:00Z")
export function toISOFormat(dateStr: string): string {
    const date = new Date(dateStr);
    return date.toISOString();
}
