import { Injectable } from '@angular/core';
import { AlertController, Platform, ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor(
    public platform: Platform,
    private toastController: ToastController,
    private alertController: AlertController
  ) { }

  public isBrowser() {
    return !this.platform.is('cordova') && !this.platform.is('capacitor');
  }

  public async showToast(message: string, type: 'success' | 'error' = 'success', duration: number = 3000): Promise<void> {
    const toast = await this.toastController.create({
      message,
      duration,
      position: 'top',
      color: type === 'success' ? 'primary' : 'danger',
      translucent: true,
    });
    await toast.present();
  }

  public async showAlert(message: string, header: string = 'Error', color: 'valid' | 'invalid', duration?: number): Promise<void> {
    const alert = await this.alertController.create({
      header,
      message,
      buttons: ['OK'],
      cssClass: `alert-${color}`,
    });
  
    await alert.present();
    await alert.onDidDismiss();
  
    if (duration) {
      setTimeout(async () => {
        await alert.dismiss();
      }, duration);
    }
  }
}
